import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../../../services/product/product.service';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {environment} from '../../../../environments/environment';
import {ScrollService} from '../../../services/scroll/scroll.service';
import {AnswerableService} from '../../../services/answerable/answerable.service';
import {CommunicationService} from '../../../services/communication/communication.service';
import {PublicityAgencyService} from '../../../services/publicity-agency/publicity-agency.service';
import {SupplierService} from '../../../services/supplier/supplier.service';
import {SessionService} from '../../../services/session/session.service';
import {FileUpload} from 'primeng/fileupload';
import {FileService} from '../../../services/file/file.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ValidatorService} from '../../../services/validator/validator.service';
import {Communication} from '../../../models/communication';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MediaService} from '../../../services/media/media.service';
import {catchError, map, Observable, ObservableInput, of} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpEventType} from '@angular/common/http';
import {VideoService} from '../../../services/video/video.service';
import {Product} from '../../../models/register/product/product';
import {ComboBoxOption} from '../../../models/combo-box-option';
import {CropperComponent} from '../../../components/cropper/cropper.component';
import {ProductFileCustomCardText} from '../../../models/register/product/product-file-custom-card-text';
import {ProductFileMovie} from '../../../models/register/product/product-file-movie';
import {ProductFileAudio} from '../../../models/register/product/product-file-audio';
import {ProductFile} from '../../../models/register/product/product-file';
import {ProductFileCustomCardTextFont} from '../../../models/register/product/product-file-custom-card-text-font';
import {ProductFileCustomCardTextArea} from '../../../models/register/product/product-file-custom-card-text-area';
import {ProductFileCustomCard} from '../../../models/register/product/product-file-custom-card';
import {ProductTargetArea} from '../../../models/register/product/product-target-area';
import {ProductDistribution} from '../../../models/register/product/product-distribution';
import {ProductDistributionDetail} from '../../../models/register/product/product-distribution-detail';
import {WorkBook, WorkSheet} from 'xlsx';
import {Answerable} from '../../../models/answerable';
import {PublicityAgency} from '../../../models/publicity-agency';
import {Supplier} from '../../../models/supplier';
import {DateService} from '../../../services/date/date.service';
import { Items, TargetItens } from 'src/app/models/targetOptions';

@Component({
  selector: 'app-register-product-edit',
  templateUrl: './register-product-edit.component.html',
  styleUrls: ['./register-product-edit.component.scss'],
})
export class RegisterProductEditComponent implements OnInit {

  successOnConfirm: boolean = false;
  successOnConfimMenuItems: MenuItem[] = [];

  id: number | null = null;
  duplicateFrom: number | null = null;
  isLoading: boolean = false;
  activeTabIndex: number = 0;

  minDate: Date = new Date();
  chipsSeparatorExp: string = ',| ';

  messages:any = 'teste';

  name: string | null = null;
  code: string | null = null;
  status: number = 1;
  kindOptions: any[] = [];
  kind: any = null;
  categoryOptions: any[] = ProductService.getCategories();
  category: any = null;
  filteredKits: any[] = [];
  kits: ComboBoxOption[] = [];
  expireAtIndeterminateOptions: any[] = ProductService.getYesNo();
  // expireAtIndeterminate: any = this.expireAtIndeterminateOptions[1];
  expireAtIndeterminate:any = false;
  expireAt: any = null;
  communicationOptions: any[] = [];
  communication: any = null;
  answerableOptions: any[] = [];
  answerable: any = null;
  publicityAgencyOptions: any[] = [];
  publicityAgency: any = null;
  supplierOptions: any[] = [];
  supplier: any = null;
  
  targetOptions:Array<TargetItens> = ProductService.getTargets();
  target: any = null;
  targetItemsOptions: Array<Items> = [];
  targetItemsSubsectionOptions: Array<Items> = [];

  targetItems: any[] = [];


  targetAreaOptions: any[] = ProductService.getTargetAreas();
  targetArea: any = null;
  filteredTargetAreas: any[] = [];
  targetAreas: any[] = [];
  tags: string[] = [];
  subjectOptions: ComboBoxOption[] = ProductService.getSubjects();
  subject: any = null;
  service: string | null = null
  // communicationPeriodStart: any = null;
  // communicationPeriodEnd: any = null;
  specification: string | null = null;
  // branches: string[] = [];
  productGoalOptions: any[] = ProductService.getProductionStage();
  productGoal: any = null;
  showcase: any[] = [];
  customCard: ProductFileCustomCard | null = null;

  rightsExpireAtIndeterminateOptions: any[] = ProductService.getYesNo();
  rightsExpireAtIndeterminate: any = null;
  rightsExpireAt: any = null;
  meansOptions: any[] = ProductService.getMeans();
  means: any[] = [];

  prefixClassifications: ComboBoxOption[] = [];
  deliveryWayOptions: any[] = ProductService.getDeliveryWays();
  printBatchActiveIndex: number = 0;
  printBatches: any[] = []
  printBatch: any = {
    id: null,
    isConfirmed:false,
    publicityAgency: null,
    supplier: null,
    quantity: null, // 0,
    unitPrice: null, // 0.00,
    expectedOn: null,
    deliveryWay: null,
    distributions: []
  };
  printBatchRestrictedPrefixOptions: ComboBoxOption[] = [];
  printBatchRestrictedPrefix: string = ''
  deliveryWays: any[] = [];
  deliveryWayByGroup: ProductDistribution[] = [];
  listDeliveryWayByGroup: ProductDistribution[] = [];
  deliveryWayAutomatic: any[] = [];
  deliveryWayDirect: any[] = [];
  deliveryWayRestrict: any[] = [];

  // COMMUNICATION DIALOG
  addCommunicationVisibility: boolean = false;
  @ViewChild('communicationImageInput')
  communicationImageInput: FileUpload | null = null;
  communicationName: string = '';
  communicationFileUploadChooseLabel: string = 'Selecionar imagem';
  canCropCommunicationImage: boolean = false;
  communicationFileName: string = '';
  communicationImageBase64: string = '';
  croppedCommunicationImage: any = '';

  // ANSWERABLE DIALOG
  addAnswerableVisibility: boolean = false;
  answerableName: string = '';
  answerableShortname: string | null = null;
  answerableEmail: string | null = null;
  answerablePrefix: string | null = null;
  answerableDv: string | null = null;
  answerableKind: string | null = null;
  answerableClassification: string | null = null;
  answerableState: string | null = null;

  // PUBLICITY AGENCY DIALOG
  addPublicityAgencyVisibility: boolean = false;
  publicityAgencyName: string = '';

  // SUPPLIER DIALOG
  addSupplierVisibility: boolean = false;
  supplierName: string = '';
  supplierFederalCode: string = '';

  // SHOWCASE
  @ViewChild('showcaseItemInput')
  showcaseItemInput: FileUpload | null = null;
  addShowcaseItemVisibility: boolean = false;
  indexShowCaseEdit:number = 0;
  showcaseItemFileName: string = '';
  showcaseItemBase64: string = '';
  copperConfig: any = {
    dragMode: 'move', // move, none
    aspectRatio: 1,
    center: true,
    guides: true,
    movable: true,
    scalable: true,
    zoomable: true,
    viewMode: 0,
    autoCrop: true,
    minContainerWidth: 901,
    minContainerHeight: 500,
  };
  @ViewChild('showcaseItemCropper')
  public showcaseItemCropper: CropperComponent | undefined;
  
  @ViewChild('showComunicationCropper')
  public showComunicationCropper: CropperComponent | undefined;

  // CUSTOM CARD
  @ViewChild('customCardInput')
  customCardInput: FileUpload | null = null;
  selectCustomCardVisibility: boolean = false;
  customCardFileName: string = '';
  customCardBase64: string = '';
  croppedCustomCard: any = '';
  oldCustomCard: ProductFileCustomCard | null = null;

  customizeItemVisibility: boolean = false;
  private backupCanvas: HTMLCanvasElement | null = null;
  private canvasx: number = 0;
  private canvasy: number = 0;
  private last_mousex: number = 0;
  private last_mousey: number = 0;
  private mousex: number = 0;
  private mousey: number = 0;
  private mousedown: boolean = false;

  customCardLabel: string = 'Campo personalizado';
  customCardMaxLength: number = 30;
  customCardFontSize: number = 18;
  customCardTextStyleOptions: any[] = [
    {label: 'Negrito', icon: 'fa-solid fa-bold', value: 'BOLD'},
    {label: 'Itálico', icon: 'fa-solid fa-italic', value: 'ITALIC'},
  ];
  customCardTextStyles: ComboBoxOption[] = [];
  customCardTextAlignOptions: ComboBoxOption[] = [
    {label: 'Alinhar à esquerda', icon: 'fa-solid fa-align-left', value: 'LEFT'},
    {label: 'Centralizar', icon: 'fa-solid fa-align-center', value: 'CENTER'},
    {label: 'Alinhar à direita', icon: 'fa-solid fa-align-right', value: 'RIGHT'},
    {label: 'Posicionar no meio', icon: 'fa-solid fa-arrows-to-dot', value: 'MIDDLE'},
  ];
  customCardTextAlign: ComboBoxOption | null = null;
  customCardFontColor: string = '#000000';
  customCardPositionX: number = 30;
  customCardPositionY: number = 249;
  customCardWidth: number = 438;
  customCardPreview: boolean = false;
  customCardTextExample: string = 'Campo personalizado';

  // MOVIE
  movieFile: any = null;
  movieInfo: any = null;
  @ViewChild('movieInput')
  movieInput: FileUpload | null = null;
  hasMovie: boolean = false;

  // AUDIO
  audioFile: any = null;
  audioInfo: any = null;
  @ViewChild('audioInput')
  audioInput: FileUpload | null = null;
  hasAudio: boolean = false;

  // FILE
  file: any = null;
  fileInfo: any = null;
  @ViewChild('fileInput')
  fileInput: FileUpload | null = null;
  hasFile: boolean = false;

  uploadProgress: number = 0;
  uploadedFileInfo: any = null;

  @ViewChild('deliveryWayAutomaticInput')
  deliveryWayAutomaticInput: FileUpload | null = null;

  @ViewChild('deliveryWayDirectInput')
  deliveryWayDirectInput: FileUpload | null = null;

  @ViewChild('deliveryWayRestrictInput')
  deliveryWayRestrictInput: FileUpload | null = null;


  //PANEL VALIDATIONS
  tabOneValidate:boolean = false;
  tabTwoValidate:boolean = false;
  tabThreeValidate:boolean = false;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private communicationService: CommunicationService,
    private answerableService: AnswerableService,
    private publicityAgencyService: PublicityAgencyService,
    private supplierService: SupplierService,
    private fileService: FileService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    if (environment.useToken) {
      this.route.queryParams.subscribe((params: Params): void => {
        const token = params['token'];

        if (!ValidatorService.isEmpty(token)) {
          SessionService.setToken(token);
          this.init();
        } else //
          this.router.navigateByUrl('/login').then((): any => this);
      });
    } else //
      this.init();
  }

  private init(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap): void => {
      this.id = paramMap.has('id') ? parseInt(paramMap.get('id')!, 10) : null;

      if (this.id !== null) {
        console.log('load: ' + this.id);
        this.isLoading = true;

        this.productService.get(this.id).pipe(
          map((event: HttpEvent<any>): any => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                this.uploadProgress = Math.round(event.loaded * 100 / event.total!);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError((error: HttpErrorResponse): ObservableInput<any> => {
            this.isLoading = false;
            this.uploadProgress = 0;

            console.error(error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: `Ocorreu um problema ao tentar carregar o registro ${this.id}.`
            });

            this.cancel();

            return of(`Retrieve of ${this.id} failed.`);
          })) //
          .subscribe((event: any): void => {
            if (typeof (event) === 'object') {
              console.log(event);
              this.isLoading = false;
              this.uploadProgress = 0;
              console.log(event.body);
              this.id = event.body.id;
              this.load(event.body);
            }
          });
      }
    });

    this.route.queryParams.subscribe((params: Params): void => {
      try {
        if (params['duplicate'] && parseInt(params['duplicate'], 10) > 0) {
          this.duplicateFrom = params['duplicate'];

          if (this.duplicateFrom !== null) {
            console.log('duplicate from: ' + this.duplicateFrom);
            this.isLoading = true;

            this.productService.get(this.duplicateFrom).pipe(
              map((event: HttpEvent<any>): any => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    this.uploadProgress = Math.round(event.loaded * 100 / event.total!);
                    break;
                  case HttpEventType.Response:
                    return event;
                }
              }),
              catchError((error: HttpErrorResponse): ObservableInput<any> => {
                this.isLoading = false;
                this.uploadProgress = 0;

                console.error(error);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erro',
                  detail: `Ocorreu um problema ao tentar carregar o registro ${this.duplicateFrom} para duplicar.`
                });

                this.cancel();

                return of(`Retrieve of ${this.duplicateFrom} to duplicate failed.`);
              })) //
              .subscribe((event: any): void => {
                if (typeof (event) === 'object') {
                  this.isLoading = false;
                  this.uploadProgress = 0;
                  this.id = null;
                  this.load(event.body, true);
                }
              });
          }
        }
      } catch (e) {
        // Do nothing.
      }
    });

    ScrollService.toTop();

    this.successOnConfimMenuItems = [
      {
        label: 'Copiar dados para novo cadastro',
        icon: 'fa-solid fa-copy',
        command: (): void => {
          // TODO: Remove. Its deprecated.
          this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['register', 'product', 'new'], {
            queryParams: {
              token: SessionService.getToken(),
              duplicate: this.id,
              time: new Date().getTime(),
            }
          });
        }
      },
      {
        label: 'Cadastrar nova peça em branco',
        icon: 'fa-regular fa-file',
        command: (): void => {
          // TODO: Remove. Its deprecated.
          this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['register', 'product', 'new'], {
            queryParams: {
              token: SessionService.getToken(),
              time: new Date().getTime(),
            }
          });
        }
      },
      {
        label: 'Voltar à lista de peças',
        icon: 'fa-solid fa-arrow-left',
        command: (): void => {
          this.cancel();
        
        }
      }
    ];

    this.productService.combobox('kind_with_icon') //
      .then((data: any): void => this.kindOptions = data);
    this.publicityAgencyService.all() //
      .then((data: any): void => this.publicityAgencyOptions = data);
    this.supplierService.all() //
      .then((data: any): void => this.supplierOptions = data);
    this.productService.combobox('prefix_classifications') //
      .then((data: any): void => {
        this.prefixClassifications = data
        this.listDeliveryWayByGroup = [];
        if(!!this.id){
            /**
             * {
            detail: {
              id: classification.value,
              label: classification.label,
              branches: classification.data,
            } as ProductDistributionDetail,
            quantity: 0
          } as ProductDistribution)
             */
            this.prefixClassifications.forEach((classification: ComboBoxOption) => {
              var item = {
                detail: {
                  id: classification.value,
                  label: classification.label,
                  branches: classification.data,
                } as ProductDistributionDetail,
                quantity: 0
              } as ProductDistribution;
              this.listDeliveryWayByGroup.push(item);
            });
        }
        
      });

    this.customCardTextAlign = this.customCardTextAlignOptions[0]
    this.printBatches.push(this.printBatch);
  }

  private load(item: any, duplicate: boolean = false): void {
    // TODO: Reset all fields
    // TODO: Populate all fields
    // TODO: Populate fields to duplicate

    this.name = item.name;
    this.code = item.code;
    this.status = 1;

    if (!duplicate) {
      this.code = item.code;
      this.status = item.status?.value;
    }

    // kind
    this.kind = null;
    try {
      setTimeout((): void => {
        this.kindOptions.forEach((option: ComboBoxOption): void => {
          if (option.value === item.kind.value) //
            this.kind = option;
        });
      }, 300);
    } catch (e) {
      // Do nothing.
    }

    this.category = item.category;

    this.expireAtIndeterminate = null;
    this.expireAt = null;
    console.log(item);
    //rightsExpireAtIndeterminate

  if ([undefined, null].includes(item.rightsExpireAtIndeterminate)) {
      try {
        setTimeout((): void => {
          this.expireAtIndeterminateOptions.forEach((option: ComboBoxOption): void => {
            if (option.value === true) //
              this.rightsExpireAtIndeterminate = true;
          });
        }, 300);
      } catch (e) {
        // Do nothing.
      }
    }

    if ([undefined, null].includes(item.expireAt)) {
      try {
        setTimeout((): void => {
          this.expireAtIndeterminateOptions.forEach((option: ComboBoxOption): void => {
            if (option.value === true) //
              this.expireAtIndeterminate = true;
          });
        }, 300);
      } catch (e) {
        // Do nothing.
      }
    } else {
      try {
        setTimeout((): void => {
          this.expireAtIndeterminateOptions.forEach((option: ComboBoxOption): void => {
            if (option.value === false) //
              this.expireAtIndeterminate = false;
          });
        }, 300);

        this.expireAt = DateService.addHours(new Date(item.expireAt), 3);
      } catch (e) {
        // Do nothing.
      }
    }

    this.communication = item.communication;
    this.answerable = item.answerable;
    this.means = item.means.map((i:any) => i.value);


    this.publicityAgency = null;
    try {
      setTimeout((): void => {
        this.publicityAgencyOptions.forEach((option: ComboBoxOption): void => {
          if (option.value === item.publicityAgency?.value) //
            this.publicityAgency = option;
        });
      }, 300);
    } catch (e) {
      // Do nothing.
    }


    this.supplier = null;
    try {
      setTimeout((): void => {
        this.supplierOptions.forEach((option: ComboBoxOption): void => {
          if (option.value === item.supplier?.value) //
            this.supplier = option;
        });
      }, 300);
    } catch (e) {
      // Do nothing.
    }

    // target
    this.target = item.target?.value;
    this.onChangeTarget(null);

    this.targetItems = item.target.items.map((e:any)=> { return e.value });

    this.populateTarget();
    this.changeDetectorRef.markForCheck();


    this.targetArea = item.targetArea?.value;
    this.tags = item.tags;

      try {
        item.targetArea.items.forEach((item: ComboBoxOption): void => {
          this.targetAreas.push(item);
        });

      } catch (e) {
        console.log('ERROR', e);
      }
    try {
      this.subject = parseInt(item.subject, 10);
    } catch (e) {
      // Do nothing.
    }

    this.service = item.service;
    // if(!!item.communicationPeriod){
      
    //  this.communicationPeriodStart =  !!item.communicationPeriod[0] ? DateService.addHours(new Date(item.communicationPeriod[0]), 3) : null
    //   this.communicationPeriodEnd = !!item.communicationPeriod[1] ?  DateService.addHours(new Date(item.communicationPeriod[1]), 3) : null
    // }

    // item.communicationPeriod?.forEach((period: string): void => {
    //   try {
    //     this.communicationPeriod.push(DateService.addHours(new Date(period), 3));
    //   } catch (e) {
    //     // Do nothing.
    //   }
    // });

//     "specification": "Wobbler com o formato de 10cm x 10cm",
    this.specification = item.specification;

//     this.branches = item.branches;
//     "branchs": [
//         "8008"
//     ],

//     "productGoal": "P",
    this.productGoal = item.productGoal;

//     "showcase": [
//         {
//             "id": 2622,
//             "name": "2022/marinha_merchandising_wobbler_cartao_1653071311467.jpg",
//             "content": "http://127.0.0.1:8080/media/2022/marinha_merchandising_wobbler_cartao_1653071311467.jpg"
//         }
//     ],
    item.showcase?.forEach((_item: any): void => {
      this.showcase.push({
        id: _item.id,
        name: _item.name,
        content: _item.content,
      })
    });

//     "rightsExpireAt": "2023-04-26T00:00:00.000Z",
    this.rightsExpireAtIndeterminate = item.rightsExpireAt ? true  :false;
    this.rightsExpireAt = !!item.rightsExpireAt ? item.rightsExpireAt  : null;
    // if ([undefined, null].includes(item.rightsExpireAt)) {
    //   try {
    //     setTimeout((): void => {
    //       this.rightsExpireAtIndeterminateOptions.forEach((option: ComboBoxOption): void => {
    //         if (option.value === true) //
    //           this.rightsExpireAtIndeterminate = true;
    //       });
    //     }, 300);
    //   } catch (e) {
    //     // Do nothing.
    //   }
    // } else {
    //   try {
    //     setTimeout((): void => {
    //       this.rightsExpireAtIndeterminateOptions.forEach((option: ComboBoxOption): void => {
    //         if (option.value === false) //
    //           this.rightsExpireAtIndeterminate = false;
    //       });
    //     }, 300);

    //     this.rightsExpireAt = DateService.addHours(new Date(item.rightsExpireAt), 3);
    //   } catch (e) {
    //     // Do nothing.
    //   }
    // }

    try {
      if (item.printBatches && item.printBatches.length > 0) {
        this.printBatch.id = item.printBatches[0].id;
        this.printBatch.quantity = item.printBatches[0].quantity;
        this.printBatch.unitPrice = item.printBatches[0].unitPrice;
        this.printBatch.isConfirmed = item.printBatches[0].isConfirmed;
        this.printBatch.expectedOn = DateService.addHours(new Date(item.printBatches[0].expectedOn), 3);
      }
    } catch (e) {
      // Do nothing.
    }

    try {
      item.deliveryWays.forEach((deliveryWay: string): void => {
        ProductService.getDeliveryWays().forEach((_deliveryWay: ComboBoxOption): void => {
          if (deliveryWay === _deliveryWay.value) //
            this.deliveryWays.push(_deliveryWay);
        });
      });
    } catch (e) {
      // Do nothing.
    }

    try {
      this.deliveryWayByGroup = [];

      if (item.deliveryWayByGroup && item.deliveryWayByGroup.length > 0) {
        item.deliveryWayByGroup.forEach((delivery: any) => this.deliveryWayByGroup.push(delivery as ProductDistribution));
      }else{
        this.listDeliveryWayByGroup.forEach((delivery: any) => this.deliveryWayByGroup.push(delivery as ProductDistribution))
      }
            
    } catch (e) {
      // Do nothing.
    }

    // deliveryWayAutomatic: any[] = [];
    try {
      if (item.deliveryWayAutomatic && item.deliveryWayAutomatic.length > 0) {
        this.deliveryWayAutomatic = [];
        item.deliveryWayAutomatic.forEach((delivery: any) => this.deliveryWayAutomatic.push(delivery as ProductDistribution));
      }
    } catch (e) {
      // Do nothing.
    }

    // deliveryWayDirect: any[] = [];
    try {
      if (item.deliveryWayDirect && item.deliveryWayDirect.length > 0) {
        this.deliveryWayDirect = [];
        item.deliveryWayDirect.forEach((delivery: any): void => {
          let distribution: ProductDistribution = delivery as ProductDistribution;

          try {
            distribution.deliveryAt = DateService.addHours(new Date(delivery.deliveryAt), 3);
          } catch (e1) {
            // Do nothing.
          }

          this.deliveryWayDirect.push(delivery);
        });
      }
    } catch (e) {
      // Do nothing.
    }

    // deliveryWayRestrict: any[] = [];
    try {
      if (item.deliveryWayRestrict && item.deliveryWayRestrict.length > 0) {
        this.deliveryWayRestrict = [];
        item.deliveryWayRestrict.forEach((delivery: any) => this.deliveryWayRestrict.push(delivery as ProductDistribution));
      }
    } catch (e) {
      // Do nothing.
    }


    if (item.file) {
      if (this.isKindMovie(item)) {
        this.hasMovie = true;
        this.movieInfo = {
          name: item.file.name,
          quality: item.file.quality,
          width: item.file.width,
          height: item.file.height,
          aspectRatio: item.file.aspectRatio,
          duration: item.file.duration,
          size: item.file.size
        };
        setTimeout((): void => this.loadMovieFile(item.file, item.file.content), 300);
      } else if (this.isKindJingle(item) || this.isKindSpot(item)) {
        this.hasAudio = true;
        this.audioInfo = {
          name: item.file.name,
          duration: item.file.duration,
          size: item.file.size
        };
        setTimeout((): void => this.loadAudioFile(item.file, item.file.content), 300);
      } else if (this.isKindCustomCard(item)) {
        this.customCard = item.file;
        console.log(item);
        console.log(`custom card`)
        // TODO
      } else {
        this.hasFile = true;
        //       this.file = file;
        this.fileInfo = {
          name: item.file.name,
          size: item.file.size
        };
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  public populateTarget(){

    this.targetItemsOptions.forEach((item) =>{
      item.checked = this.targetItems.includes(item.value);

      if(!!item.items && item.items.length > 0){
        item.items.forEach((e) => {
          e.checked = this.targetItems.includes(e.value);
          this.targetItemsSubsectionOptions.push(e);
        });
      }
    })
  }

  public targetOnChecked(list:Array<any>, item:any){
    console.log(list);
    console.log(item);
  }

  public cancel(): void {
    this.router.navigate(['register', 'product'], {
      skipLocationChange:true,
      queryParams: {
        token: SessionService.getToken()
      }
    });
  }


  public confirm(draft: boolean = false): void {
    // TODO: Verify if it is a update or create

    let item: Product = {
      // 1st form
      id: this.id,
      name: this.name,
      kind: this.kind,
      category: this.category,
      kits: this.kits,
      expireAt: (this.expireAtIndeterminate !== null && this.expireAtIndeterminate === false) ? this.expireAt : null,
      communication: this.communication,
      answerable: this.answerable,
      publicityAgency: this.publicityAgency,
      supplier: this.supplier,
      target: {
        value: this.target,
        items: this.selectedTarget
      } as ComboBoxOption,
      targetArea: {
        value: this.targetArea,
        items: this.targetAreas
      } as ProductTargetArea,
      tags: this.tags,
      subject: this.subject,
      service: this.service,
      // communicationPeriod: [this.communicationPeriodStart, this.communicationPeriodEnd],
      specification: this.specification,
      productGoal:!this.isVirtualType ? this.productGoal : 'AF',
      showcase: this.showcase,
      //file: ProductFile;

      // 2st form
      rightsExpireAt: (this.rightsExpireAtIndeterminate !== null && this.rightsExpireAtIndeterminate === false) ? this.rightsExpireAt : null,
      means: this.means,

      // 3rd form
      printBatches: this.printBatches,
      deliveryWays: [null],
      deliveryWayByGroup: this.deliveryWayByGroup,
      deliveryWayAutomatic: this.deliveryWayAutomatic,
      deliveryWayDirect: this.deliveryWayDirect,
      deliveryWayRestrict: this.deliveryWayRestrict,
    } as Product;

    if(this.isKindCustomCard() || this.isKindMovie() || this.isKindJingle() || this.isKindSpot()){
      item.productGoal = 'P';
    }else{
      item.productGoal = this.productGoal;
    }
   
    if((!this.canShowGoalItem() || this.isKindMovie() || this.isKindJingle() || this.isKindSpot()) && !this.isEdit){
      item.deliveryWays  = ['C'];
    }else{
      item.deliveryWays = [];
      this.deliveryWays.forEach(deliveryWay => item.deliveryWays.push(deliveryWay.value));
    }
   

    if (this.isKindCustomCard()) {
      item.file = this.getProductFileCustomCardText();
    } else if (this.isKindMovie()) {
      const file: ProductFileMovie = {
        // id?: number | string;
        name: this.uploadedFileInfo?.name,
        // content?: string;
        size: this.uploadedFileInfo?.size,
        // url?: string;
        tempName: this.uploadedFileInfo?.tempName,
        // remove?: boolean;
        duration: this.movieInfo?.duration,
        width: this.movieInfo?.width,
        height: this.movieInfo?.height,
        quality: this.movieInfo?.quality,
        aspectRatio: this.movieInfo?.aspectRatio,
      } as ProductFileMovie;
      item.file = file;
    } else if (this.isKindSpot() || this.isKindJingle()) {
      const file: ProductFileAudio = {
        // id?: number | string;
        name: this.uploadedFileInfo?.name,
        // content?: string;
        size: this.uploadedFileInfo?.size,
        // url?: string;
        tempName: this.uploadedFileInfo?.tempName,
        // remove?: boolean;
        duration: this.audioInfo?.duration,
      } as ProductFileAudio;
      item.file = file;
    } else if (this.isKindOther()) {
      const file: ProductFile = {
        // id?: number | string;
        name: this.uploadedFileInfo?.name,
        // content?: string;
        size: this.uploadedFileInfo?.size,
        // url?: string;
        tempName: this.uploadedFileInfo?.tempName,
        // remove?: boolean;
      } as ProductFile;
      item.file = file;
    }

    if (this.validate(draft)) {
      this.isLoading = false;
      this.uploadProgress = 0;

      if(this.activeTabIndex == 0 && this.tabOneValidate){
        this.activeTabIndex =1;
      }

      if(this.activeTabIndex == 1 && this.tabTwoValidate){
        this.activeTabIndex =2;
      }
      if(this.tabOneValidate && this.tabTwoValidate && this.tabThreeValidate){
        this.isLoading = true;
        const object: Observable<any> = (this.id === null) ? this.productService.add(item, draft) : //
          this.productService.update(this.id, item);

          object.pipe(
          map((event: HttpEvent<any>): any => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                this.uploadProgress = Math.round(event.loaded * 100 / event.total!);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError((error: HttpErrorResponse): ObservableInput<any> => {
            this.isLoading = false;
            this.uploadProgress = 0;

            console.error(error);

            return of(`Confirmation failed.`);
          }))
          .subscribe((event: any): void => {
            if (typeof (event) === 'object') {
              this.isLoading = false;
              this.uploadProgress = 0;

              try {
                this.id = event.body.id;

                // TODO: Navigate to edit page with ID

                if (!ValidatorService.isEmpty(event.body.code)) {
                  this.successOnConfirm = true;
                  this.code = event.body.code;
                  this.status = event.body.status;
                }

                this.init();

              } catch (e) {
                console.error(e);

                this.messageService.add({
                  severity: 'error',
                  summary: 'Erro',
                  detail: `Ocorreu um problema ao tentar salvar o registro ${this.id}.`
                });
              }
            }
          });
      }

     
    }
  }

  private getProductFileCustomCardText(): ProductFileCustomCardText {
    const file: ProductFileCustomCardText = {
      // id?: number | string;
      name: this.customCard?.name,
      content: this.customCard?.content,
      // size?: number;
      // url?: string;
      // tempName?: string;
      // remove?: boolean;
      kind: 'text',
      // label: this.customCardLabel,
      maxLength: this.customCardMaxLength,
      font: {
        size: this.customCardFontSize,
        styles: [],
      } as ProductFileCustomCardTextFont,
      align: this.customCardTextAlign?.value,
      color: this.customCardFontColor.toUpperCase(),
      area: {
        x: this.customCardPositionX,
        y: this.customCardPositionY,
        width: this.customCardWidth,
      } as ProductFileCustomCardTextArea,
      textExample: this.customCardTextExample,
    } as ProductFileCustomCardText;

    for (let i: number = 0; i < this.customCardTextStyles.length; i++) {
      const style: ComboBoxOption = this.customCardTextStyles[i];

      if (!ValidatorService.isEmpty(style?.value)) //
        file.font.styles.push('' + style?.value);
    }

    return file;
  }

  private showWarningValidate(msg: string){
    this.messageService.add({
      severity: 'warn',
      summary: 'Aviso',
      detail: msg
    });
  }

  private validate(draft: boolean): boolean {
    window.scrollTo({top: 0, behavior: 'smooth'});

    let canContinue: boolean = true;
    if(this.activeTabIndex == 0){
      let continueStepOne = true;
       if (ValidatorService.isEmpty(this.name)) {
        this.showWarningValidate('O nome precisa ser fornecido')

        this.errorInRequiredField('name', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if (ValidatorService.isEmpty(this.kind)) {
        this.showWarningValidate('O tipo precisa ser selecionado.')
        this.errorInRequiredField('kind', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if (ValidatorService.isEmpty(this.communication)) {
        this.showWarningValidate('A ação de comunicação precisa ser fornecida.')
        this.errorInRequiredField('communication', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if (ValidatorService.isEmpty(this.answerable)) {
        this.showWarningValidate('A área demandante precisa ser fornecida.')
        this.errorInRequiredField('answerable', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      
      if (ValidatorService.isEmpty(this.supplier)) {
        this.showWarningValidate('O fornecedor precisa ser selecionado.')
        this.errorInRequiredField('supplier', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      
      if (ValidatorService.isEmpty(this.publicityAgency)) {
        this.showWarningValidate('O criador precisa ser selecionado.')
        this.errorInRequiredField('publicity-agency', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }


     
      if (ValidatorService.isEmpty(this.tags)) {
        this.showWarningValidate('As tags precisam ser fornecidas.')
        this.errorInRequiredField('tags', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;

      } else if (this.tags.length === 1) {
        this.showWarningValidate('Forneça mais de uma tag.')
        this.errorInRequiredField('tags', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if (ValidatorService.isEmpty(this.service)) {
        this.showWarningValidate('O produto precisa ser fornecido.')
        this.errorInRequiredField('service', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if (ValidatorService.isEmpty(this.productGoal) && !this.isVirtualType && (this.canShowGoalItem())) {
        this.showWarningValidate('A finalidade da peça precisa ser selecionada.')
        this.errorInRequiredField('product-goal', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      } else if (ValidatorService.isEmpty(this.specification) && !this.isKindMovie() && !this.isKindCustomCard()) {
        this.showWarningValidate('A especificação precisa ser fornecida.')
        this.errorInRequiredField('specification', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if (this.canShowShowCaseField() && ValidatorService.isEmpty(this.showcase)) {
        this.showWarningValidate('Ao menos uma imagem precisa ser fornecida ao mostruário.')
        this.errorInRequiredField('showcase');
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

       if (ValidatorService.isEmpty(this.expireAtIndeterminate)) {
        this.showWarningValidate('A validade se é indeterminada precisa ser fornecida.')
        this.errorInRequiredField('expire-at-indeterminate', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      } else if (!this.expireAtIndeterminate && ValidatorService.isEmpty(this.expireAt)) {
        this.showWarningValidate('A validade precisa ser fornecida.')
        this.errorInRequiredField('expire-at', false, 0);
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if(this.isVirtualType && !this.isKindCustomCard() && ValidatorService.isEmpty(this.printBatch.unitPrice)){
        this.showWarningValidate('Informe o custo de produção da peça.')
        this.errorInRequiredField('unitPrice', false, 0);
        canContinue = false;
        this.tabOneValidate = false;
      }


      if (this.isKindMovie() && ValidatorService.isEmpty(this.movieInfo)) {
        this.errorInRequiredField('movie', false, 0);
        this.showWarningValidate('É necessário enviar o arquivo do filme.')
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      } else if ((this.isKindSpot() || this.isKindJingle()) && ValidatorService.isEmpty(this.audioInfo)) {
        this.errorInRequiredField('audio', false, 0);
        this.showWarningValidate('É necessário enviar o arquivo do spot.')
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      } else if (!ValidatorService.isEmpty(this.kind) && ValidatorService.isEmpty(this.fileInfo) && (this.productGoal === 'AF') && !this.isKindMovie() && !this.isKindSpot() && !this.isKindJingle() && !this.isKindCustomCard()) {
        this.errorInRequiredField('file', false, 0);
        this.showWarningValidate('É necessário enviar o arquivo')
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if(ValidatorService.isEmpty(this.target)){
        this.errorInRequiredField('target', false, 0);
        this.showWarningValidate('Informe o público alvo')
        canContinue = false;
        continueStepOne = false;
        this.tabOneValidate = false;
      }

      if(continueStepOne){
        this.tabOneValidate = true;
      }
    }

    

    if(this.activeTabIndex == 1 && this.tabOneValidate){
      let continueStepTwo = true;
    
      if(this.isNotVirtualType()){
        console.log({
          
        })
        if (ValidatorService.isEmpty(this.rightsExpireAtIndeterminate)) {
          this.errorInRequiredField('rights-expire-at-indeterminate', false, 1);
          canContinue = false;
          continueStepTwo = false;
          this.tabTwoValidate = false;
        } else if (!this.rightsExpireAtIndeterminate && ValidatorService.isEmpty(this.rightsExpireAt)) {
          this.showWarningValidate('Informe a data de validade da foto')
          this.errorInRequiredField('rights-expire-at', false, 1);
          canContinue = false;
          continueStepTwo = false;
          this.tabTwoValidate = false;
        }
      }

      if (ValidatorService.isEmpty(this.means)) {
        this.errorInRequiredField('means', false, 1);
        this.showWarningValidate('O meio de divulgação precisa ser selecionado.')
        canContinue = false;
        continueStepTwo = false;
        this.tabTwoValidate = false;
      }

      


       if (ValidatorService.isEmpty(this.targetArea)) {
        this.showWarningValidate('A praça precisa ser selecionada.')
        this.errorInRequiredField('target-area', false, 0);
        canContinue = false;
        this.tabTwoValidate = false;
        continueStepTwo = false;
      } else if (['E', 'M', 'P'].includes(this.targetArea) && this.targetAreas.length === 0) {
        this.showWarningValidate( 'Ao menos um local da praça precisa ser fornecido.')
        this.errorInRequiredField('target-areas', false, 0);
        canContinue = false;
        continueStepTwo = false;
        this.tabTwoValidate = false;
      }


      if(continueStepTwo){
        this.tabTwoValidate = true;
      }

    }

    if(this.activeTabIndex == 2){
      let continueStepThree = true;
      
      if(!this.isVirtualType)
      {
          if (ValidatorService.isEmpty(this.printBatch.quantity) || (this.printBatch.quantity <= 0)) {
          this.showWarningValidate('A quantidade precisa ser fornecida.');
          this.errorInRequiredField('print-batch-quantity', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
        }

        if (ValidatorService.isEmpty(this.printBatch.unitPrice) || (this.printBatch.unitPrice <= 0)) {
          this.showWarningValidate('O valor unitário precisa ser fornecido.');
          this.errorInRequiredField('print-batch-unit-price', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
        }

        if (ValidatorService.isEmpty(this.printBatch.expectedOn)) {
          this.showWarningValidate('A data prevista da chegada deve ser fornecida.');
          this.errorInRequiredField('print-batch-expected-on', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
        }
      }else{
        this.tabThreeValidate = true;
      }
     

      if (ValidatorService.isEmpty(this.deliveryWays)  && !this.isVirtualType) {
        this.showWarningValidate('A forma de distribuição da impressão precisa ser selecionada.');
        this.errorInRequiredField('print-batch-delivery-way', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
      } else {
        // Automatic
        if ((this.deliveryWaysExists('A')) && (this.deliveryWayAutomatic.length === 0)) {
          this.showWarningValidate('Um prefixo deve ser adicionado para a distribuição automática.');
          this.errorInRequiredField('print-batch-restricted-prefix1', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
        }

        // Restricted
        if ((this.deliveryWaysExists('D')) && (this.deliveryWayDirect.length === 0)) {
          this.showWarningValidate( 'Um prefixo deve ser adicionado para a distribuição direta.');
          this.errorInRequiredField('print-batch-restricted-prefix2', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
        }


        // Restricted
        if ((this.deliveryWaysExists('P')) && (this.deliveryWayRestrict.length === 0)) {
          this.showWarningValidate('Um prefixo deve ser adicionado para o estoque restrito.');
          this.errorInRequiredField('print-batch-restricted-prefix3', false, 2);
          continueStepThree = false;
          canContinue = false;
          this.tabThreeValidate = false;
        }
      }

      if(continueStepThree){
        this.tabThreeValidate = true;
      }

    }
    

    console.log({
      one:this.tabOneValidate,
      two:this.tabTwoValidate,
      three:this.tabThreeValidate,
      pg:this.productGoal
    })


    if(((this.tabOneValidate && this.tabTwoValidate) && this.productGoal !== 'P')){
      this.tabThreeValidate = true;
      this.activeTabIndex = 1;
      canContinue = true;
    }

    if((this.tabOneValidate && this.tabTwoValidate) && this.productGoal !== 'P'){
      canContinue = true;
      this.tabThreeValidate = true;
    }
    

    if(((this.tabOneValidate && this.tabTwoValidate) && this.productGoal == null)){
      canContinue = true;
      this.tabThreeValidate = true;
    }

    return canContinue;
  }

  public onChangeValidate(event: any): void {
    // console.log(event);

    try {
      if (ValidatorService.isEmpty(event.target)) //
        return;

      const target = event.target;
      const id = target.id;
      const type = target.tagName.toLowerCase();

      this.errorInRequiredField(id, true);

      // console.log(id, target.tagName.toLowerCase());

      // TODO: Verify if label has a required class

      switch (type) {
        case 'input':
        case 'textarea':
          if (ValidatorService.isEmpty(target.value)) //
            this.errorInRequiredField(id, false);
          break;
      }
    } catch (e) {
      // Do nothing.
    }
  }

  private errorInRequiredField(id: string, remove: boolean = false, tabIndex: number = 0, changeTab: boolean = true): void {
    const inputName: HTMLElement | null = document.getElementById(id);

    if (!remove) {
      inputName?.classList.add('ng-invalid', 'ng-dirty');
      //@ts-ignore
      inputName?.previousSibling?.classList.add('error');
      //@ts-ignore
      inputName?.parentNode?.classList.add('error');

      if (changeTab) //
        setTimeout((): void => {
          // this.activeTabIndex = tabIndex;
          setTimeout((): void => inputName?.focus(), 100);
        }, 200);
    } else {
      inputName?.classList.remove('ng-invalid', 'ng-dirty');
      //@ts-ignore
      inputName?.previousSibling?.classList.remove('error');
      //@ts-ignore
      inputName?.parentNode?.classList.remove('error');
    }
  }

  //====================================================================================================================
  //
  // 1. TAB GENERAL (INFORMATION)
  //
  //====================================================================================================================

  public isKindCustomCard(item: any = null): boolean {
    if (item === null) //
      item = this;

    return item.kind && item.kind.value === 61;
  }

  public isKindKit(item: any = null): boolean {
    if (item === null) //
      item = this;

    // 28 - Welcome Kit
    // 36 - Kit
    return item.kind && [28, 37].includes(item.kind.value);
  }

  public isKindMovie(item: any = null): boolean {
    if (item === null) //
      item = this;

    return item.kind && item.kind.value === 34;
  }

  public isKindSpot(item: any = null): boolean {
    if (item === null) //
      item = this;

    return item.kind && item.kind.value === 35;
  }

  public isKindJingle(item: any = null): boolean {
    if (item === null) //
      item = this;

    return item.kind && item.kind.value === 36;
  }

  public isKindOther(item: any = null): boolean {
    if (item === null) //
      item = this;

    return item.kind && ![0, 28, 34, 35, 36, 37].includes(item.kind.value);
  }

  filterKits(event: any): void {
    const query = event.query;
    this.filteredKits = [];

    if (!ValidatorService.isEmpty(query)) {
      const queryWithoutDiatricts = query.normalize('NFKD').replace(/\p{Diacritic}/gu, '').toUpperCase();

      this.kindOptions.forEach((kind): void => {
        const label = kind.label.normalize('NFKD').replace(/\p{Diacritic}/gu, '').toUpperCase();

        // Verify if the query is no label and not a custom card
        if (label.includes(queryWithoutDiatricts) && (kind.value !== 0)) //
          this.filteredKits.push(kind);
      });
    }
  }

  public changeExpireAtIndeterminate(evt:any){
    this.expireAt = this.expireAtIndeterminate ? '9999-12-31' : null;
  }

  showAddCommunicationDialog(): boolean {
    this.addCommunicationVisibility = true;
    return false;
  }

  showAddAnswerableDialog(): boolean {
    this.addAnswerableVisibility = true;
    return false;
  }

  showAddPublicityAgencyDialog(): boolean {
    this.addPublicityAgencyVisibility = true;
    return false;
  }

  showAddSupplierDialog(): boolean {
    this.addSupplierVisibility = true;
    return false;
  }

  public filterCommunication(event: any): void {
    this.communicationService.all(event.query, true) //
      .then((data: any): any => this.communicationOptions = data);
  }

  public filterAnswerable(event: any): void {
    this.answerableService.all(event.query, true)
      .then((data: any): void => this.answerableOptions = data);
  }

  get selectedTarget(): Array<{label:string, value:number}>{
    let list: Array<{label:string, value:number}> = [];

    this.targetItemsOptions.forEach((item) =>{

      //IF HAS CHILDREN ON TARGET ITEM OPTION
      if(item.checked){
        item.items?.forEach((s) =>{
          if(s.checked){
            //PUSH SUB ON LIST ITEMS
            list.push({
              label:s.label,
              value:s.value
            });
          }
        })

        //PUSH PARENT ON LIST ITEMS
        list.push({
          label:item.label,
          value:item.value
        });
      }
    })
  

    return list;


  }

  public onChangeTarget(event: any): void {
    this.targetItemsOptions = [];
    // this.targetItems = [];
    this.targetItemsSubsectionOptions = [];
    
    let options = this.targetOptions.filter((item) =>{ 
      return item.value == this.target
    })[0];

    this.targetItemsOptions = options.items;
  }

  public onChangeTargetItem(item:Items)
  {
    item.checked = !item.checked;
    if(!!item.items){
      if(item.checked){
        if(this.targetItemsSubsectionOptions.length > 0)
          item.items.forEach((it) => {this.targetItemsSubsectionOptions.push(it)});
        else
          this.targetItemsSubsectionOptions = item.items;
      }else{
        let valuesToRemove = item.items.map(item => item.value);
        this.targetItemsSubsectionOptions = this.targetItemsSubsectionOptions.filter(subItem => !valuesToRemove.includes(subItem.value));
      }
    }
  }

  public onChangeTargetSubItem(evt:any, item:Items){
    item.checked = !item.checked;
  }


  public onShowcaseItemUpload(event: any): void {
    this.showcaseItemInput?.clear();
    
    if(this.isMultipleImages){
      for(var item in event.files){
        FileService.load(event.files[item]).then((base64: string): any => {

          let image: any = {
            originBaseFile: base64,
            name: event.files[item].name,
            content: base64,
            remove: false,
          };
          this.showcase.push(image);

          this.changeDetectorRef.detectChanges();
        });
      }
    }else{
      FileService.load(event.files[0]).then((base64: string): any => {
        this.showcaseItemFileName = event.files[0].name;
        this.showcaseItemBase64 = base64;

        this.changeDetectorRef.detectChanges();


        setTimeout((): boolean => this.addShowcaseItemVisibility = true, 100);
      });
    }
  }

  public onShowcaseItemDrop(event: CdkDragDrop<string[]>): void {
    if (!this.isLoading) //
      moveItemInArray(this.showcase, event.previousIndex, event.currentIndex);
  }

  public removeShowcaseItem(event: any, item: any, i: number): boolean {
    if (!this.isLoading) //
      if (ValidatorService.isEmpty(item.id)) //
        this.showcase.splice(i, 1);
      else //
        item.remove = true;

    return false;
  }

   public editShowCaseItem(item: any, i: number) {
    this.indexShowCaseEdit = i;
    this.showcaseItemFileName = item.name;
    this.showcaseItemBase64 = item.originBaseFile;
    this.changeDetectorRef.detectChanges();
    setTimeout((): boolean => this.addShowcaseItemVisibility = true, 100);

  }

  public onCustomCardUpload(event: any): void {
    this.customCardInput?.clear();

    if (event.files.length > 0) {
      FileService.load(event.files[0]).then((base64: string): any => {
        if (this.customCard) {
          this.oldCustomCard = {
            name: this.customCard.name,
            content: this.customCard.content,
          } as ProductFileCustomCard;
        }

        this.customCardFileName = event.files[0].name;
        this.customCardBase64 = base64;
        this.selectCustomCardVisibility = true;
      });
    }
  }

  public editCustomCard(event: any): boolean {
    if (this.isLoading) //
      return false;

    this.customizeItemVisibility = true;

    // Canvas
    setTimeout((): void => {
      const elcanvas: HTMLElement | null = document.getElementById('custom-card-canvas');
      if (elcanvas) {
        const canvas: HTMLCanvasElement = elcanvas as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        const img: HTMLImageElement = new Image();
        img.onload = (): void => {
          // Once the image is loaded, we will get the width & height of the image
          let loadedImageWidth: number = img.width;
          let loadedImageHeight: number = img.height;

          // get the scale
          // it is the min of the 2 ratios
          let scaleFactor: number = Math.max(canvas!.width / img.width, canvas!.height / img.height);

          // Finding the new width and height based on the scale factor
          let newWidth: number = img.width * scaleFactor;
          let newHeight: number = img.height * scaleFactor;

          // get the top left position of the image
          // in order to center the image within the canvas
          let x: number = (canvas.width / 2) - (newWidth / 2);
          let y: number = (canvas.height / 2) - (newHeight / 2);

          // When drawing the image, we have to scale down the image
          // width and height in order to fit within the canvas
          ctx?.drawImage(img, x, y, newWidth, newHeight);

          // Create a backup canvas from current canvas
          this.backupCanvas = document.createElement('canvas');
          this.backupCanvas!.width = canvas.width;
          this.backupCanvas!.height = canvas.height;
          this.backupCanvas!.getContext('2d')!.drawImage(canvas, 0, 0);

          // Verify if already exists line to draw
          if (ctx && this.customCardPositionX > 0 && this.customCardPositionY > 0 && this.customCardWidth > 0) {
            // Clear canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(this.backupCanvas!, 0, 0);

            ctx.beginPath();
            const width: number = this.customCardWidth;
            const height: number = 0;
            ctx.rect(this.customCardPositionX, this.customCardPositionY, width, height);
            ctx.strokeStyle = '#FF0000';
            ctx.lineWidth = 1;
            // Dashes are 5px and spaces are 3px
            ctx.setLineDash([5, 3]);
            ctx.stroke();
          }
        };
        img.src = this.customCard?.content!;
      }

      document.getElementById('custom-card-label')?.focus();
    }, 300);

    return false;
  }

  public onMovieUpload(event: any): void {
    this.movieInput?.clear();
    this.uploadedFileInfo = null;

    if (event.files.length > 0) {
      const file: File = event.files[0];

      // Reference: https://unitconverter.io/megabytes/bytes/64
      // 64mb = 67,108,864 bytes
      // if (file.size > 67108864) {
      //   this.messageService.add({
      //     severity: 'warn',
      //     summary: 'Aviso',
      //     detail: 'O vídeo precisa ser de tamanho menor ou igual à 64mb. Tamanho atual: ' + FileService.formatBytes(file.size) + '.'
      //   });
      //   return;
      // }

      // Prepare movie to show and retrieve details
      this.hasMovie = true;
      this.movieFile = file;
      this.movieInfo = null;

      this.uploadFile(file, (file: File): any => setTimeout(async (): Promise<void> => {
        const url: string = URL.createObjectURL(file);
        this.loadMovieFile(file, url);
      }, 100));
    }
  }

  private loadMovieFile(file: any, url: string): void {
    const source: HTMLElement | null = document.getElementById('movie-player');

    if (source) {
      // @ts-ignore
      source.src = url;
      // @ts-ignore
      source.parentNode.onloadedmetadata = (event: any): void => {
        this.movieInfo.width = event.target.videoWidth;
        this.movieInfo.height = event.target.videoHeight;

        this.movieInfo.quality = VideoService.detectQuality(this.movieInfo.width, this.movieInfo.height);
        if (this.movieInfo.quality === null) //
          this.movieInfo.quality = 'Outra';

        this.movieInfo.aspectRatio = VideoService.detectAspectRatio(this.movieInfo.width, this.movieInfo.height);
        this.movieInfo.duration = MediaService.getDuration(event.target);
      };
      // @ts-ignore
      source.parentNode.load();

      this.movieInfo = {
        name: file.name,
        size: file.size,
        width: 0,
        height: 0,
        quality: null,
        aspectRatio: null,
        duration: '00:00:00'
      };
    }
  }

  public onAudioUpload(event: any): void {
    this.audioInput?.clear();
    this.uploadedFileInfo = null;

    if (event.files.length > 0) {
      const file: File = event.files[0];

      // Reference: https://unitconverter.io/megabytes/bytes/10
      // 10mb = 10,485,760 bytes
      if (file.size > 10485760) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'O áudio precisa ser de tamanho menor ou igual à 10mb. Tamanho atual: ' + FileService.formatBytes(file.size) + '.'
        });
        return;
      } 

      // Prepare movie to show and retrieve details
      this.hasAudio = true;
      this.audioFile = file;
      this.audioInfo = null;

      this.uploadFile(file, (file: File): any => setTimeout(async (): Promise<void> => {
        const url: string = URL.createObjectURL(file);
        this.loadAudioFile(file, url);
      }, 100));
    }
  }

  private loadAudioFile(file: File, url: string): void {
    const source: HTMLElement | null = document.getElementById('audio-player');

    if (source) {
      // @ts-ignore
      source.src = url;
      // @ts-ignore
      source.parentNode.onloadedmetadata = (event: any): string => //
      // @ts-ignore
      this.audioInfo.duration = MediaService.getDuration(event.target);
      // @ts-ignore
      source.parentNode.load();

      this.audioInfo = {
        name: file.name,
        size: file.size,
        duration: '00:00:00'
      };
    }
  }

  public onFileUpload(event: any): void {
    this.fileInput?.clear();
    this.uploadedFileInfo = null;

    if (event.files.length > 0) {
      const file: File = event.files[0];

      // Reference: https://unitconverter.io/megabytes/bytes/100
      // 100mb = 104,857,600 bytes
      if (file.size > 104857600) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'O áudio precisa ser de tamanho menor ou igual à 100mb. Tamanho atual: ' + FileService.formatBytes(file.size) + '.'
        });
        return;
      }

      // Prepare movie to show and retrieve details
      this.hasFile = true;
      this.file = file;
      this.fileInfo = {
        name: file.name,
        size: file.size
      };

      this.uploadFile(file);
    }
  }

  //====================================================================================================================
  //
  // UTIL METHOD
  //
  //====================================================================================================================
  get expireAtIsIndeterminate(){
    return this.expireAt;
    
  }


  //====================================================================================================================
  //
  // 2. TAB COPYRIGHT
  //
  //====================================================================================================================

  public canShowTabImage():boolean{
    return this.isKindJingle() || this.isKindMovie() || this.isKindSpot() || this.isKindCustomCard() ? false : true;
  }

  public canShowSquareFieldOnFirstTab():boolean{
    return this.isKindJingle() || this.isKindMovie() || this.isKindSpot() ? false : true;
  }

  public canShowGoalItem(){
    return this.isKindMovie() || this.isKindCustomCard() ? false : true;
  }

  public canShowRightsExpireAtArea(): boolean {
    return !(this.rightsExpireAtIndeterminate === null || this.rightsExpireAtIndeterminate);
  }

  public canShowShowCaseField():boolean{
    return this.isKindJingle() || this.isKindMovie() || this.isKindSpot() ? false : true;
  }

  get isMultipleImages(){
    return  this.isKindJingle() || this.isKindMovie() || this.isKindSpot() ? false : true;
  }


  get showValueProduction(){
     return  this.isKindJingle() || this.isKindMovie() || this.isKindSpot() ? true : false;
  }

  public onChangeMeansItem(event: any, item: any): void {
    const i: number = this.means.findIndex(e => e.value === item.value);
    if (i > -1) //
      this.means.splice(i, 1);
    else
      this.means.push(item);

    // console.log(this.means);
  }

  public onChangeTargetArea(event: any): void {
    this.targetAreas = [];

    if (['E', 'M', 'P'].includes(this.targetArea)) //
      setTimeout((): void =>
          //@ts-ignore
          document.getElementById('target-areas')?.getElementsByClassName('p-element')[0]?.focus(), //
        200);
  }

  filterTargetArea(event: any): void {
    const query = event.query;

    let kind: any = null;
    switch (this.targetArea) {
      case 'E': // States of Brazil
        kind = 'brazil_states';
        break;
      case 'M': // Cities of Brazil
        kind = 'brazil_cities';
        break;
      case 'P': // Countries
        kind = 'countries';
        break;
    }

    this.productService.combobox(kind, query) //
      .then((data: any): void => this.filteredTargetAreas = data);
  }

  //====================================================================================================================
  //
  // 3. TAB BATCH PRINTING AND DISTRIBUTION
  //
  //====================================================================================================================

  public isNotVirtualType(){
    return !(this.isKindJingle() || this.isKindMovie()  || this.isKindSpot() || this.isKindCustomCard());
  }

  get isEdit(){
    let check = this.id != null && this.id ? true : false;
    return !check
  }

  get isVirtualType():boolean{
    return (this.isKindJingle() || this.isKindMovie()  || this.isKindSpot() || this.isKindCustomCard());
  }

  public canShowTabBatchPrintingAndDistribution(): boolean {
    return (this.productGoal !== null && this.productGoal === 'P') && !this.isVirtualType;
  }

  // public onChangeDeliveryWay(event: any): void {
  //   // console.log(event);   
  //   // console.log(this.prefixClassifications);
  //
  //   this.printBatch.distributions = [];
  //
  //   // Para todos (For all)
  //   if (this.printBatch.deliveryWay === 'C') {
  //     this.prefixClassifications.forEach((classification: ComboBoxOption): void => //
  //       this.printBatch.distributions.push({
  //         detail: {
  //           id: classification.value,
  //           label: classification.label,
  //           branches: classification.data,
  //         } as ProductDistributionDetail,
  //         quantity: 0
  //       } as ProductDistribution));
  //   }
  //   // // Restrito (Restrict)
  //   // else if (this.printBatch.deliveryWay === 'P') {
  //   //
  //   // }
  // }

  public isDeliveryWayChecked(item: any): boolean {
    const i: number = this.deliveryWays.findIndex(e => e.value === item.value);
    return i > -1;
  }

  public onChangeDeliveryWayItem(event: any, item: any): void {
    const i: number = this.deliveryWays.findIndex(e => e.value === item.value);
    if (i > -1) //
      this.deliveryWays.splice(i, 1);
    else
      this.deliveryWays.push(item);

    // console.log(this.deliveryWays);

    // this.printBatch.distributions = [];


    if(item.value == "C" && (!!this.id || this.id == null) && this.deliveryWayByGroup.length == 0){
      console.log('here 2');
       this.prefixClassifications.forEach((classification: ComboBoxOption): number => //
        this.deliveryWayByGroup.push({
          detail: {
            id: classification.value,
            label: classification.label,
            branches: classification.data,
          } as ProductDistributionDetail,
          quantity: 0
        } as ProductDistribution));
    }
    //
    // // // Restrito (Restrict)
    // // else if (this.printBatch.deliveryWay === 'P') {
    // //
    // // }
  }

  public deliveryWaysExists(value: string): boolean {
    return this.deliveryWays.findIndex(e => e.value === value) > -1
  }

  // public removePrintBatchDistribution(event: any, item: any, i: number): void {
  //   this.confirmationService.confirm({
  //     target: event.target!,
  //     header: 'Confirmação',
  //     message: 'Deseja realmente remover <strong>' + item.label + '</strong>?',
  //     icon: 'fa-solid fa-exclamation-triangle',
  //     acceptLabel: 'Sim',
  //     acceptButtonStyleClass: 'p-button-danger',
  //     rejectLabel: 'Não',
  //     defaultFocus: 'reject',
  //     accept: (): void => {
  //       this.printBatch.distributions.splice(i, 1);
  //     },
  //     reject: (): void => {
  //       // reject action
  //     }
  //   });
  // }
  //
  // public filterPrintBatchRestrictedPrefix(event: any): void {
  //   this.productService.combobox('branches', event.query) //
  //     .then((data: any): void => this.printBatchRestrictedPrefixOptions = data.filter((item: any): boolean => //
  //       !this.printBatch.distributions.some((distribution: any): boolean => distribution['id'] === item['value'])));
  // }
  //
  // public addRestrictedPrefixOnPrintBatch(event: any): void {
  //   this.printBatch.distributions.push({
  //     detail: {
  //       id: event.value,
  //       label: event.label
  //     } as ProductDistributionDetail,
  //     quantity: 0
  //   } as ProductDistribution);
  //
  //   setTimeout((): void => {
  //     this.printBatchRestrictedPrefix = '';
  //     const input: HTMLInputElement = document.getElementById('print-batch-restricted-prefix')! as HTMLInputElement;
  //     input.focus();
  //   }, 100);
  // }

  //====================================================================================================================
  //
  // 4. DIALOGS
  //
  //====================================================================================================================

  public onCommunicationFileUpload(event: any): void {
    this.communicationImageInput?.clear();

    if (event.files.length > 0) //
      FileService.load(event.files[0]).then((base64: string): any => {
        this.communicationFileUploadChooseLabel = 'Mudar imagem';
        this.communicationFileName = event.files[0].name;
        this.canCropCommunicationImage = true;
        this.communicationImageBase64 = base64;
      });
  }

  public onCommunicationImageCropped(event: ImageCroppedEvent): void {
    this.croppedCommunicationImage = event.base64;
  }

  public onLoadImageToCropFailed(): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Aviso',
      detail: 'Problemas ao carregar a imagem. Tente novamente ou verifique se é possível abrir a imagem em um editor de imagens.'
    });
  }

  setCroppedImage(cropperEl?: CropperComponent) : void{
    let instanceField = cropperEl!.cropper!.getCroppedCanvas({
      width: 496,
      height: 496,
      minWidth: 496,
      minHeight: 496,
      maxWidth: 496,
      maxHeight: 496,
      fillColor: '#F3F3F3',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toBlob((blob: Blob | null)=> {
      // Read the Blob as DataURL using the FileReader API
      const reader: FileReader = new FileReader();
      reader.onload = (): void =>{
        // this.showcaseItemFileName = event.files[0].name;
        this.croppedCommunicationImage = reader.result;
      }
      reader.readAsDataURL(blob!);
    }, 'image/png', 1);

    console.log(instanceField);
  }

  cancelAddCommunication(): void {
    this.communicationName = '';
    this.communicationFileUploadChooseLabel = 'Selecionar imagem';
    this.canCropCommunicationImage = false;
    this.communicationImageBase64 = '';
    this.croppedCommunicationImage = '';
    this.addCommunicationVisibility = false;
  }

  addCommunication(): void {
    this.isLoading = true;
    if (ValidatorService.isEmpty(this.communicationName)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O nome da comunicação precisa ser fornecido.'
      });
      return;
    }

    if (ValidatorService.isEmpty(this.communicationFileName)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'A imagem da comunicação precisa ser fornecida.'
      });
      return;
    }

    this.setCroppedImage(this.showComunicationCropper);

    setTimeout(() =>{
      const communication: Communication = {
        name: this.communicationName,
        image: this.croppedCommunicationImage
      } as Communication;

      this.communicationService.add(communication).then(data => {
        if (data && data.added) {
          this.communicationOptions.push({
            label: data.item.name,
            value: data.item.id,
            thumb: data.item.image,
          });
          this.communication = this.communicationOptions.at(this.communicationOptions.length - 1);

          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'A comunicação foi criada.'
          });

          this.cancelAddCommunication();
        }
      }).finally(() =>{
        this.isLoading = false;
      });
    },300)
    
  }

  cancelAddAnswerable(): void {
    this.answerableName = '';
    this.answerableShortname = null;
    this.answerableEmail = null;
    this.answerablePrefix = null;
    this.answerableDv = null;
    this.answerableKind = null;
    this.answerableClassification = null;
    this.answerableState = null;
    this.addAnswerableVisibility = false;
  }

  addAnswerable(): void {
    if (ValidatorService.isEmpty(this.answerableName)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O nome da área demandante precisa ser fornecido.'
      });
      return;
    }

    const answerable: Answerable = {
      name: this.answerableName,
      shortname: this.answerableShortname,
      email: this.answerableEmail,
      prefix: this.answerablePrefix,
      dv: this.answerableDv,
      kind: this.answerableKind,
      classification: this.answerableClassification,
      state: this.answerableState
    } as Answerable;
    this.answerableService.add(answerable).then(data => {
      if (data && data.added) {
        this.answerableOptions.push({
          label: data.item.name,
          value: data.item.id,
        });
        this.answerable = this.answerableOptions.at(this.answerableOptions.length - 1);

        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'A área demandante foi criada.'
        });

        this.cancelAddAnswerable();
      }
    });
  }

  cancelAddPublicityAgency(): void {
    this.publicityAgencyName = '';
    this.addPublicityAgencyVisibility = false;
  }

  addPublicityAgency(): void {
    if (ValidatorService.isEmpty(this.publicityAgencyName)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O nome do criador precisa ser fornecido.'
      });
      return;
    }

    const publicityAgency: PublicityAgency = {
      name: this.publicityAgencyName
    } as PublicityAgency;
    this.publicityAgencyService.add(publicityAgency).then(data => {
      if (data && data.added) {
        this.publicityAgencyOptions.push({
          label: data.item.name,
          value: data.item.id,
        });
        this.publicityAgency = this.publicityAgencyOptions.at(this.publicityAgencyOptions.length - 1);

        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'O criador foi criado.'
        });

        this.cancelAddPublicityAgency();
      }
    });
  }

  cancelAddSupplier(): void {
    this.supplierName = '';
    this.supplierFederalCode = '';
    this.addSupplierVisibility = false;
  }

  addSupplier(): void {
    if (ValidatorService.isEmpty(this.supplierName)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O nome do fornecedor precisa ser fornecido.'
      });
      return;
    }

    if (ValidatorService.isEmpty(this.supplierFederalCode)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'O cnpj do fornecedor precisa ser fornecido.'
      });
      return;
    }

    const supplier: Supplier = {
      name: this.supplierName,
      federalCode: this.supplierFederalCode
    } as Supplier;
    this.supplierService.add(supplier).then(data => {
      if (data && data.added) {
        this.supplierOptions.push({
          label: data.item.name,
          value: data.item.id,
        });
        this.supplier = this.supplierOptions.at(this.supplierOptions.length - 1);

        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'O fornecedor foi criado.'
        });

        this.cancelAddSupplier();
      }
    }).catch((e) =>{
      this.messageService.add({
        severity:'error',
        summary:'Aviso',
        detail:!!e.error.message ? e.error.message : 'Houve um erro, tente novamente!'
      })
    });
  }

  public onChangeShowcaseItemCropperReset(event: any): void {
    this.showcaseItemCropper!.cropper!.reset();
  }

  public onChangeShowcaseItemCropperDecreaseZoom(event: any): void {
    this.showcaseItemCropper!.cropper!.zoom(-0.1);
  }

  public onChangeShowcaseItemCropperIncreaseZoom(event: any): void {
    this.showcaseItemCropper!.cropper!.zoom(0.1);
  }

   public onChangeComunicationCropperReset(event: any): void {
    this.showComunicationCropper!.cropper!.reset();
  }

  public  onChangeComunicationCropperDecreaseZoom(event: any): void {
    this.showComunicationCropper!.cropper!.zoom(-0.1);
  }

  public  onChangeComunicationCropperIncreaseZoom(event: any): void {
    this.showComunicationCropper!.cropper!.zoom(0.1);
  }

  public cancelAddShowcaseItem(): void {
    this.isLoading = false;
    this.addShowcaseItemVisibility = false;

    this.changeDetectorRef.detectChanges();

    setTimeout((): void => {
      this.showcaseItemFileName = '';
      this.showcaseItemBase64 = '';
      this.showcaseItemCropper!.cropper!.reset();
    }, 100);
  }

  public addShowcaseItem(): void {
    this.isLoading = false;
    this.showcaseItemCropper!.cropper!.getCroppedCanvas({
      width: 496,
      height: 496,
      minWidth: 496,
      minHeight: 496,
      maxWidth: 496,
      maxHeight: 496,
      fillColor: '#F3F3F3',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toBlob((blob: Blob | null): void => {
      // Read the Blob as DataURL using the FileReader API
      const reader: FileReader = new FileReader();
      reader.onloadend = (): void => {
        let image: any = {
          name: this.showcaseItemFileName,
          content: reader.result,
          remove: false,
        };
        this.showcase[this.indexShowCaseEdit] = image;
        // this.showcase.push(image);

        this.errorInRequiredField('showcase', true, 0);

        this.cancelAddShowcaseItem();
      };
      reader.readAsDataURL(blob!);
    }, 'image/jpeg', 1);
  }

  public onCustomCardCropped(event: ImageCroppedEvent): void {
    this.croppedCustomCard = event.base64;
  }

  public cancelSelectCustomCard(): void {
    this.customCardFileName = '';
    this.customCardBase64 = '';
    this.croppedCustomCard = '';
    this.selectCustomCardVisibility = false;

    if (this.oldCustomCard) //
      this.customCard = {
        name: this.oldCustomCard.name,
        content: this.oldCustomCard.content,
      } as ProductFileCustomCard;
  }

  public selectCustomCard(): void {
    this.customCard = {
      name: this.customCardFileName,
      content: this.croppedCustomCard,
    } as ProductFileCustomCard;

    this.oldCustomCard = null;
    this.cancelSelectCustomCard();
    this.editCustomCard(null);
  }

  public onCustomCardCanvasMouseDown(event: MouseEvent): void {
    this.last_mousex = event.clientX - this.canvasx;
    this.last_mousey = event.clientY - this.canvasy;
    this.mousedown = true;
  }

  public onCustomCardCanvasMouseUp(event: MouseEvent): void {
    this.mousedown = false;
  }

  public onCustomCardCanvasMouseMove(event: MouseEvent): void {
    const tip: HTMLElement | null = document.getElementById('custom-card-tip');
    if (tip !== null) //
      (tip as HTMLDivElement).style.display = 'none';

    this.mousex = event.clientX - this.canvasx;
    this.mousey = event.clientY - this.canvasy;

    if (this.mousedown) {
      // var ctx = canvas.getContext('2d');

      const elcanvas: HTMLElement | null = document.getElementById('custom-card-canvas');
      if (elcanvas) {
        const canvas: HTMLCanvasElement = elcanvas as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        if (ctx) {
          // Clear canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(this.backupCanvas!, 0, 0);

          ctx.beginPath();
          const width: number = this.mousex - this.last_mousex;
          const height: number = 0; // mousey - last_mousey;
          ctx.rect(this.last_mousex - 150, this.last_mousey - 80, width, height);
          ctx.strokeStyle = '#FF0000';
          ctx.lineWidth = 1;
          // Dashes are 5px and spaces are 3px
          ctx.setLineDash([5, 3]);
          ctx.stroke();
        }
      }
    }

    let x: number = this.last_mousex - 150;
    let y: number = this.last_mousey - 80;

    if (x < 0) x = 0;
    if (y < 0) y = 0;

    this.customCardPositionX = x;
    this.customCardPositionY = y;

    if (this.mousedown) {
      let w: number = this.mousex - this.last_mousex;
      if (w < 0) w *= -1;

      this.customCardWidth = w;
    }
  }

  public onCustomCardChangeProperties(event: any) {
    if (this.customCardPreview) {
      const file: ProductFileCustomCardText = this.getProductFileCustomCardText();
      delete file.content;

      // this.isLoading = true;
      //
      // setTimeout((): void => {
      //   this.isLoading = false;
      // }, 2000);
      //
      // console.log(file);

      this.productService.generateCustomCardPreview(file).pipe(
        map((event: HttpEvent<any>): any => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.uploadProgress = Math.round(event.loaded * 100 / event.total!);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse): ObservableInput<any> => {
          this.isLoading = false;
          this.uploadProgress = 0;
          // this.uploadedFileInfo = null;

          console.error(error);

          return of(`${file.name} upload failed.`);
        })) //
        .subscribe((event: any): void => {
          if (typeof (event) === 'object') {
            this.isLoading = false;
            this.uploadProgress = 0;
            // this.uploadedFileInfo = event.body;
            //
            // if (callback) //
            //   callback.call(this, file);

            const reader: FileReader = new FileReader();
            reader.onloadend = (): void => {
              if (this.customCard) //
                this.customCard.preview = reader.result as string;
            };
            reader.readAsDataURL(event.body);
          }
        });
    }
  }

  public cancelCustomizeItem(): void {
    // this.customCardFileName = '';
    // this.customCardBase64 = '';
    // this.croppedCustomCard = '';
    // this.selectCustomCardVisibility = false;
    //
    // if (this.oldCustomCard) //
    //   this.customCard = [{
    //     name: this.oldCustomCard.name,
    //     image: this.oldCustomCard.image,
    //   }];
    this.customizeItemVisibility = false;
  }

  public saveItemCustomization(): void {
    // let image: any = {
    //   name: this.customCardFileName,
    //   image: this.croppedCustomCard,
    // };
    // this.customCard = [image];

    this.cancelCustomizeItem();
  }

  private uploadFile(file: any, callback: Function | null = null): void {
    this.isLoading = true;
    this.uploadProgress = 0;
    this.uploadedFileInfo = null;

    const formData: FormData = new FormData();
    formData.append('file', file);

    this.fileService.upload(formData).pipe(
      map((event: HttpEvent<any>): any => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.uploadProgress = Math.round(event.loaded * 100 / event.total!);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse): ObservableInput<any> => {
        this.isLoading = false;
        this.uploadProgress = 0;
        this.uploadedFileInfo = null;


        return of(`${file.name} upload failed.`);
      })) //
      .subscribe((event: any): void => {
        if (typeof (event) === 'object') {
          this.isLoading = false;
          this.uploadProgress = 0;
          this.uploadedFileInfo = event.body;

          if (callback) //
            callback.call(this, file);
        }
      });
  }

  public download(url: string): void {
    window.location.href = url;
  }

  public deliveryWayCanBeRemoved(item: any): boolean {
    return item.orderItem === undefined || item.orderItem === null || item.orderItem < 1
  }

  public onDeliveryWayAutomaticUpload(event: any): void {
    this.deliveryWayAutomaticInput?.clear();

    if (event.files.length > 0)
      FileService.load(event.files[0], 'array').then((adata: any): any => {
        // console.log(adata);

        import('xlsx').then(xlsx => {
          const data: Uint8Array = new Uint8Array(adata);
          const workbook: WorkBook = xlsx.read(data, {type: 'array'});

          const sheetName: string = workbook.SheetNames[0];
          const worksheet: WorkSheet = workbook.Sheets[sheetName];

          const jsonData = xlsx.utils.sheet_to_json(worksheet, {header: 1});
          // const output = document.getElementById('output');
          // output.innerHTML = JSON.stringify(jsonData, null, 2);
          // console.log(JSON.stringify(jsonData, null, 2));

          jsonData.forEach((row: any, index: number): void => {
            if ((index === 0) || (row.length !== 2)) //
              return;

            let branchCode: string = row[0].toString();
            let quantity: number = parseInt(row[1], 10);

            while (branchCode.length < 4) //
              branchCode = '0' + branchCode;

            this.productService.combobox('branches', branchCode) //
              .then((resultData: any): void => {
                // console.log(branchCode, resultData);

                if (resultData && resultData.length > 0) //
                  this.deliveryWayAutomatic.push({
                    detail: {
                      id: resultData[0].value,
                      label: resultData[0].label
                    } as ProductDistributionDetail,
                    quantity: quantity
                  } as ProductDistribution);
              });
          });
        });
      });
  }

  public removeDeliveryWayAutomatic(event: any, item: any, i: number): void {
    this.confirmationService.confirm({
      target: event.target!,
      header: 'Confirmação',
      message: 'Deseja realmente remover <strong>' + item.detail.label + '</strong>?',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: (): void => {
        this.deliveryWayAutomatic.splice(i, 1);
      },
      reject: (): void => {
        // reject action
      }
    });
  }

  public filterDeliveryWayAutomatic(event: any): void {
    this.productService.combobox('branches', event.query) //
      .then((data: any): void => this.printBatchRestrictedPrefixOptions = data.filter((item: any): boolean => //
        !this.deliveryWayAutomatic.some((distribution: any): boolean => distribution['id'] === item['value'])));
  }

  public addDeliveryWayAutomatic(event: any): void {
    this.deliveryWayAutomatic.push({
      detail: {
        id: event.value,
        label: event.label
      } as ProductDistributionDetail,
      quantity: 0
    } as ProductDistribution);

    setTimeout((): void => {
      this.printBatchRestrictedPrefix = '';
      const input: HTMLInputElement = document.getElementById('print-batch-restricted-prefix1')! as HTMLInputElement;
      input.focus();
    }, 100);
  }

  public onDeliveryWayDirectUpload(event: any): void {
    this.deliveryWayDirectInput?.clear();

    if (event.files.length > 0)
      FileService.load(event.files[0], 'array').then((adata: any): any => {
        // console.log(adata);

        import('xlsx').then(xlsx => {
          const data: Uint8Array = new Uint8Array(adata);
          const workbook: WorkBook = xlsx.read(data, {type: 'array', cellDates: true});

          const sheetName: string = workbook.SheetNames[0];
          const worksheet: WorkSheet = workbook.Sheets[sheetName];

          const jsonData = xlsx.utils.sheet_to_json(worksheet, {header: 1});
          // const output = document.getElementById('output');
          // output.innerHTML = JSON.stringify(jsonData, null, 2);
          // console.log(JSON.stringify(jsonData, null, 2));

          jsonData.forEach((row: any, index: number): void => {
            if ((index === 0) || (row.length !== 3)) //
              return;

            let branchCode: string = row[0].toString();
            let quantity: number = parseInt(row[1], 10);
            let deliveryAt: Date = row[2];

            while (branchCode.length < 4) //
              branchCode = '0' + branchCode;

            this.productService.combobox('branches', branchCode) //
              .then((resultData: any): void => {
                // console.log(branchCode, resultData);

                if (resultData && resultData.length > 0) //
                  this.deliveryWayDirect.push({
                    detail: {
                      id: resultData[0].value,
                      label: resultData[0].label
                    } as ProductDistributionDetail,
                    quantity: quantity,
                    deliveryAt: deliveryAt
                  } as ProductDistribution);
              });
          });
        });
      });
  }

  public removeDeliveryWayDirect(event: any, item: any, i: number): void {
    this.confirmationService.confirm({
      target: event.target!,
      header: 'Confirmação',
      message: 'Deseja realmente remover <strong>' + item.detail.label + '</strong>?',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: (): void => {
        this.deliveryWayDirect.splice(i, 1);
      },
      reject: (): void => {
        // reject action
      }
    });
  }

  public filterDeliveryWayDirect(event: any): void {
    this.productService.combobox('branches', event.query) //
      .then((data: any): void => this.printBatchRestrictedPrefixOptions = data.filter((item: any): boolean => //
        !this.deliveryWayDirect.some((distribution: any): boolean => distribution['id'] === item['value'])));
  }

  public addDeliveryWayDirect(event: any): void {
    this.deliveryWayDirect.push({
      detail: {
        id: event.value,
        label: event.label
      } as ProductDistributionDetail,
      quantity: 0
    } as ProductDistribution);

    setTimeout((): void => {
      this.printBatchRestrictedPrefix = '';
      const input: HTMLInputElement = document.getElementById('print-batch-restricted-prefix2')! as HTMLInputElement;
      input.focus();
    }, 100);
  }

  public onDeliveryWayRestrictUpload(event: any): void {
    this.deliveryWayRestrictInput?.clear();

    if (event.files.length > 0)
      FileService.load(event.files[0], 'array').then((adata: any): any => {
        // console.log(adata);

        import('xlsx').then(xlsx => {
          const workbook: WorkBook = xlsx.read(new Uint8Array(adata), {type: 'array'});

          const sheetName: string = workbook.SheetNames[0];
          const worksheet: WorkSheet = workbook.Sheets[sheetName];

          const jsonData = xlsx.utils.sheet_to_json(worksheet, {header: 1});
          // console.log(JSON.stringify(jsonData, null, 2));

          jsonData.forEach((row: any, index: number): void => {
            if ((index === 0) || (row.length !== 2)) //
              return;

            let branchCode: string = row[0].toString();
            let quantity: number = parseInt(row[1], 10);

            while (branchCode.length < 4) //
              branchCode = '0' + branchCode;

            this.productService.combobox('branches', branchCode) //
              .then((resultData: any): void => {
                // console.log(branchCode, resultData);

                if (resultData && resultData.length > 0) //
                  // this.printBatch.distributions.push({
                  //   detail: {
                  //     id: resultData[0].value,
                  //     label: resultData[0].label
                  //   } as ProductDistributionDetail,
                  //   quantity: quantity
                  // } as ProductDistribution);
                  this.deliveryWayRestrict.push({
                    detail: {
                      id: resultData[0].value,
                      label: resultData[0].label
                    } as ProductDistributionDetail,
                    quantity: quantity
                  } as ProductDistribution);
              });
          });
        });
      });
  }

  public removeDeliveryWayRestrict(event: any, item: any, i: number): void {
    this.confirmationService.confirm({
      target: event.target!,
      header: 'Confirmação',
      message: 'Deseja realmente remover <strong>' + item.detail.label + '</strong>?',
      icon: 'fa-solid fa-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      defaultFocus: 'reject',
      accept: (): void => {
        this.deliveryWayRestrict.splice(i, 1);
      },
      reject: (): void => {
        // reject action
      }
    });
  }

  public filterDeliveryWayRestrict(event: any): void {
    this.productService.combobox('branches', event.query) //
      .then((data: any): void => this.printBatchRestrictedPrefixOptions = data.filter((item: any): boolean => //
        !this.deliveryWayRestrict.some((distribution: any): boolean => distribution['id'] === item['value'])));
  }

  public addDeliveryWayRestrict(event: any): void {
    this.deliveryWayRestrict.push({
      detail: {
        id: event.value,
        label: event.label
      } as ProductDistributionDetail,
      quantity: 0
    } as ProductDistribution);

    setTimeout((): void => {
      this.printBatchRestrictedPrefix = '';
      const input: HTMLInputElement = document.getElementById('print-batch-restricted-prefix3')! as HTMLInputElement;
      input.focus();
    }, 100);
  }

}
